import axios from 'axios';
import { IVehicleRecommendationsApiResponse, Vehicle } from './types';

// TODO: Set this max. value on the server
const maxRecommendations = 10;

export enum RECOMMENDATION_ALGORITHMS {
    SAME_SIMILAR = 'blended',
    SAME = 'same',
    SIMILAR = 'similar',
    BROWSE = 'browse'
}

export function getVehicleRecommendations(
    stockNumberList: string[],
    nearestStoreId: string,
    visitorId: string,
    recommendationType: RECOMMENDATION_ALGORITHMS
): Promise<Vehicle[]> {
    try {
        if (stockNumberList && stockNumberList.length > 0) {
            const sortedStockNumberList = stockNumberList.sort();
            const url = `/home/api/recommendations/${recommendationType}?sn=${sortedStockNumberList.reduce(
                    (f, s) => `${f}&sn=${s}`
                )}&storeId=${nearestStoreId}&visitorId=${visitorId}`;
            return axios
                .get<IVehicleRecommendationsApiResponse>(url)
                .then(response => {
                    if (response && response.data && response.data.length > 0) {
                        return response.data.slice(0, maxRecommendations);
                    }
                    return [];
                })
                .catch(() => []);
        }
        return Promise.resolve([]);
    } catch {
        return Promise.resolve([]);
    }
}
