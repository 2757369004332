import { useEffect, Suspense, useState } from 'react';
import { useHomeStore, HeroContext } from './Store';
import { fetchAsyncData, getComponentAndData } from './services';
import { IExperienceV2, Names } from './Store/models';
import HomeBase from './HomeBase';
import FirstTimeHero from './FirstTimeHero';

function HeroRefactor() {
    const store = useHomeStore();
    const [component, setComponent] = useState<JSX.Element>();

    function ReturnComponent(componentName: string) {
        switch (componentName) {
            case Names.HomeBase:
                setComponent(<HomeBase />);
                break;
            case Names.FirstTime:
                setComponent(<FirstTimeHero />);
                break;
            default:
                setComponent(<FirstTimeHero />);
                break;
        }
    }

    useEffect(() => {
        fetchAsyncData().then((experiences) => {
            const experienceData = getComponentAndData(experiences);
            store.setInitialValues({
                experiences: experiences as unknown[],
                component: experienceData.name,
                experienceData,
                isLoading: false,
            });
            ReturnComponent(experienceData.name);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!component) {
        return (
            <div style={{ textAlign: 'center', paddingTop: '300px' }}>
                <hzn-loading tone="brand" label="loading content" size="large"></hzn-loading>
            </div>
        );
    }
    return (
        <Suspense
            fallback={
                <div style={{ textAlign: 'center', paddingTop: '300px' }}>
                    <hzn-loading tone="brand" size="large"></hzn-loading>
                </div>
            }
        >
            <HeroContext.Provider value={(store as unknown) as IExperienceV2}>{component}</HeroContext.Provider>
        </Suspense>
    );
}

export default HeroRefactor;
