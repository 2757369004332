import { RECOMMENDATION_ALGORITHMS } from '../../../api/vehicle-recommendations-api';
import { viewedVehiclesHeaderIcon, recommendedIcon, recentSearchesHeaderIcon } from './svg';

export const returnVisitorLocalStorage = {
    key: 'kmxHP:returnVisitor',
};
export type IReturnVisitorLocalStorage = boolean;

export const viewedVehiclesLocalStorage = {
    key: 'viewedVehicles',
};
export enum HeroTheme {
    Dark = 'dark',
    Light = 'light',
}

export enum VehicleStatuses {
    Sold = 'Sold',
    OnTestDrive = 'On Test Drive',
    Reserved = 'Reserved',
    Shipping = 'Shipping',
    ComingSoon = 'Coming Soon',
    OffSite = 'Off Site',
    NotAvailable = 'Not Available',
}
export const soldVehicleStatusCodes = [360, 370, 760];
export const onTestDriveStatusCodes = [340, 345, 740];
export const reservedStatusCodes = [347, 350, 747, 750];
export const reservedConditionalStatusCodes = [330, 730];
export const shippingStatusCodes = [640, 650];
export const shippingConditionalStatusCodes = [100];
export const otherUnavailableStatusCodes = [250, 270, 300, 320, 325, 700, 720, 735];
export const statusCodesForUnavailableVehicles = [
    ...soldVehicleStatusCodes,
    ...onTestDriveStatusCodes,
    ...reservedStatusCodes,
    ...reservedConditionalStatusCodes,
    ...shippingStatusCodes,
    ...shippingConditionalStatusCodes,
    ...otherUnavailableStatusCodes,
];

export enum ProgressionActivities {
    ReservationRequested = 'ReservationRequested',
    ReservationCreated = 'ReservationCreated',
    ReservationUpdated = 'ReservationUpdated',
    ReservationCancelled = 'ReservationCancelled',
    ReservationRemoved = 'ReservationRemoved',
    ReservationFailed = 'ReservationFailed',
    VehicleOrderCreated = 'VehicleOrderCreated',
    VehicleOrderSubmitted = 'VehicleOrderSubmitted',
    VehicleOrderDeleted = 'VehicleOrderDeleted',
    MaxCareSelected = 'MaxCareSelected',
    MaxCareRemoved = 'MaxCareRemoved',
    PreQualificationsSubmitted = 'Pre-QualificationsSubmitted',
    PreQualificationsReceivingDone = 'Pre-QualificationsReceivingDone',
    PreQualificationsExpired = 'Pre-QualificationsExpired',
    CreditApplicationSubmitted = 'CreditApplicationSubmitted',
    CreditApplicationDecisionReceived = 'CreditApplicationDecisionReceived',
    CreditApplicationExpired = 'CreditApplicationExpired',
    CreditApplicationViewed = 'CreditApplicationViewed',
    CreditApplicationSaved = 'CreditApplicationSaved',
    InstantOfferCompleted = 'InstantOfferCompleted',
    TransferCheckoutCreated = 'TransferCheckoutCreated',
    TransferCheckoutCompleted = 'TransferCheckoutCompleted',
    TransferCheckoutAbandoned = 'TransferCheckoutAbandoned',
    TransferOrderRequestSubmitted = 'TransferOrderRequestSubmitted',
    TransferOrderAwaitingApproval = 'TransferOrderAwaitingApproval',
    TransferOrderApproved = 'TransferOrderApproved',
    TransferOrderDenied = 'TransferOrderDenied',
    TransferOrderCancelled = 'TransferOrderCancelled',
    TransferOrderTendered = 'TransferOrderTendered',
    TransferOrderCompleted = 'TransferOrderCompleted',
    PayoffAmountVerified = 'PayoffAmountVerified',
    PayoffCreated = 'PayoffCreated',
    VehicleAcquisitionOrderCreated = 'VehicleAcquisitionOrderCreated',
    DocumentRequested = 'DocumentRequested',
    DocumentUploaded = 'DocumentUploaded',
    DocumentTransactionCompleted = 'DocumentTransactionCompleted',
    SavedSearchCreated = 'SavedSearchCreated',
    SavedSearchUpdated = 'SavedSearchUpdated',
    SavedSearchRemoved = 'SavedSearchRemoved',
    AppointmentScheduled = 'AppointmentScheduled',
    AppointmentConfirmed = 'AppointmentConfirmed',
    AppointmentRescheduled = 'AppointmentRescheduled',
    AppointmentCanceled = 'AppointmentCanceled',
}

export const LOADING_SPINNER_CLASS = 'loading-spinner';
export const MOBILE_TABLET_BREAKPOINT = 900;
export const FULL_STORY_HOME_PAGE_ID = 'HP';
export const MAX_RECENTLY_VIEWED_VEHICLE_TILES = 10;
export const MAX_BEST_MATCH_VEHICLE_TILES = 10;
export const BEST_MATCH_VERSION = 'BestMatchV4';
export const DEFAULT_RECOMMENDATION_ALGORITHM = RECOMMENDATION_ALGORITHMS.SAME_SIMILAR;
export const ENABLE_TRANSFER_TILE_EVENT = 'kmxHomebaseEnableTransferTile';

export const DAYS_BEFORE_HOMEBASE_VISITOR_EXPIRES = 30;

export enum HomebaseTab {
    RecentlyViewed = 0,
    Recommended = 1,
    Searched = 2,
}

export const TAB_LIST_ARIA_LABEL = 'Personalized vehicle shopping tools';
const TAB_ARIA_ID_PREFIX = 'kmx-hb-tab-';
const TAB_PANEL_ARIA_ID_PREFIX = 'kmx-hb-tab-panel-';

export const HOMEBASE_CAROUSELS: {
    defaultCarouselOrder: HomebaseTab[];
    byId: {
        [key: number]: {
            label: string;
            ariaLabel: string;
            carouselName: string;
            carouselAnalyticsName: string;
            tabButtonId: string;
            tabPanelElementId: string;
            initiationAnalyticsLabel: string;
            dataAnalyticsAttr: string;
            tabButtonIcon: JSX.Element;
        };
    };
} = {
    defaultCarouselOrder: [HomebaseTab.RecentlyViewed, HomebaseTab.Searched, HomebaseTab.Recommended],
    byId: {
        [HomebaseTab.RecentlyViewed]: {
            label: ' Viewed',
            ariaLabel: 'Recently Viewed Vehicles',
            carouselName: 'viewed',
            carouselAnalyticsName: 'Recently Viewed',
            tabButtonId: `${TAB_ARIA_ID_PREFIX}${HomebaseTab.RecentlyViewed}`,
            tabPanelElementId: `${TAB_PANEL_ARIA_ID_PREFIX}${HomebaseTab.RecentlyViewed}`,
            initiationAnalyticsLabel: 'HP Home Base Viewed Vehicles Init',
            dataAnalyticsAttr: 'Prop5=Homepage Home Base Hero Tile Recently Viewed Car',
            tabButtonIcon: viewedVehiclesHeaderIcon,
        },
        [HomebaseTab.Recommended]: {
            label: ' You might like',
            ariaLabel: 'Recommended Vehicles',
            carouselName: 'recommended',
            carouselAnalyticsName: 'Recommended',
            tabButtonId: `${TAB_ARIA_ID_PREFIX}${HomebaseTab.Recommended}`,
            tabPanelElementId: `${TAB_PANEL_ARIA_ID_PREFIX}${HomebaseTab.Recommended}`,
            initiationAnalyticsLabel: 'HP Home Base Vehicle Recs Init',
            dataAnalyticsAttr: 'Prop5=Homepage Home Base Hero Tile Recommended Car',
            tabButtonIcon: recommendedIcon,
        },
        [HomebaseTab.Searched]: {
            label: ' Searched',
            ariaLabel: 'Recent Vehicle Searches',
            carouselName: 'searched',
            carouselAnalyticsName: 'Searched',
            tabButtonId: `${TAB_ARIA_ID_PREFIX}${HomebaseTab.Searched}`,
            tabPanelElementId: `${TAB_PANEL_ARIA_ID_PREFIX}${HomebaseTab.Searched}`,
            initiationAnalyticsLabel: 'HP Home Base Recent Searches Init',
            dataAnalyticsAttr: 'Prop5=Homepage Home Base Hero Tile Searched Car',
            tabButtonIcon: recentSearchesHeaderIcon,
        },
    },
};

export const HOME_BASE_RECOMMENDATION_ANALYTICS = {
    [RECOMMENDATION_ALGORITHMS.SAME_SIMILAR]: {
        IMPRESSION_EVENT_NAME: 'HP Home Base Vehicle Recs Impression',
        MODULE_NAME: 'VR|Homepage|Single Banner:hp-HeroHomebaseMVP|Blended',
    },
    [RECOMMENDATION_ALGORITHMS.SAME]: {
        IMPRESSION_EVENT_NAME: 'HP Home Base Vehicle Recs Impression',
        MODULE_NAME: 'VR|Homepage|Single Banner:hp-HeroHomebaseMVP|Same-Model',
    },
    [RECOMMENDATION_ALGORITHMS.SIMILAR]: {
        IMPRESSION_EVENT_NAME: 'HP Home Base Vehicle Recs Impression',
        MODULE_NAME: 'VR|Homepage|Single Banner:hp-HeroHomebaseMVP|Similar-Vehicles',
    },
    [RECOMMENDATION_ALGORITHMS.BROWSE]: {
        IMPRESSION_EVENT_NAME: 'HP Home Base Vehicle Recs Impression',
        MODULE_NAME: 'VR|Homepage|Top 10|Browse',
    },
};
