import * as ReactDOM from 'react-dom';
import onDomContentLoaded from '../../utilities/onDomContentLoaded';
import BudgetCalculatorContainer from './budget-calculator/BudgetCalculatorContainer';
import { initLinkFarmInteractions } from '../home/link-farm/linkFarmInteractions';
import InstantOffersLoader from '../../components/instant-offers/InstantOffersLoader';
import { instantOffersSectionId } from '../../components/instant-offers/utils/constants';
import SocialProofContainer from '../../components/carousels/social-proof/SocialProofContainer';
import ResearchArticlesContainer from '../../components/carousels/research-articles/ResearchArticlesContainer';
import StatePricingDisclaimerContainer from '../../components/StatePricingDisclaimerContainer';
import ErrorBoundary from '../../components/ErrorBoundary';
import HeroContainer from './hero/HeroContainer';
import HeroRefactor from './HeroRefactor';
import initDialogs from './dialogs';
import * as nearestStoreLinkBuilder from './utilities/nearestStoreLinkBuilder';
import { isReturnHomePageVisitor, trackFirstTimeHomePageVisitor } from './utilities/helpers';
import { FULL_STORY_HOME_PAGE_ID } from './utilities/constants';
import '../../../scss/pages/home/main.scss';
import * as analytics from '../../utilities/analytics';
import { revealAnimation } from '../../utilities/revealAnimation';
import { ServerSideExperimentProvider } from './campaigns/ServerSideExperimentContext';
import { FBSProvider } from '../../context';
import ModalHandler from '@kmx/shared-components/modalverse/ModalHandler';
import { isAuthenticated } from '../../utilities/cookieHelper';
import { LocalCarsLoader } from './local-cars/LocalCarsLoader';

function startup() {
    initLinkFarmInteractions();

    revealAnimation({
        triggerEl: '.research-articles',
        headingEl: '.research-articles--heading',
        contentEl: '.research-articles--carousel-container',
        refreshPriority: -1,
        offsetFromTopOfEl: 220,
    });

    revealAnimation({
        triggerEl: '.link-farm-tabs',
        headingEl: '.link-farm-tabs--heading',
        contentEl: '.link-farm-tabs--tab-bar, .link-farm-tabs--tab-content',
        refreshPriority: -2,
        offsetFromTopOfEl: 220,
    });

    nearestStoreLinkBuilder.buildAllLinks();
    const heroContainer = document.getElementById('hero') as HTMLElement;
    const heroConainerNew = document.getElementById('hero-new') as HTMLElement;
    if (!featureFlags.enableHomeBaseRefactor && (isReturnHomePageVisitor() || isAuthenticated())) {
        if (heroContainer) {
            ReactDOM.render(
                <ErrorBoundary>
                    <ModalHandler>
                        <ServerSideExperimentProvider>
                            <FBSProvider>
                                <HeroContainer fullStoryPageId={FULL_STORY_HOME_PAGE_ID} />
                            </FBSProvider>
                        </ServerSideExperimentProvider>
                    </ModalHandler>
                </ErrorBoundary>,
                heroContainer
            );
        }
    } else {
        setTimeout(function () {
            analytics.track('HP Brand Hero Init', true);
        }, 1000);
        trackFirstTimeHomePageVisitor();
    }

    if (featureFlags.enableHomeBaseRefactor) {
        ReactDOM.render(
            <ErrorBoundary>
                <ModalHandler>
                    <ServerSideExperimentProvider>
                        <FBSProvider>
                            <HeroRefactor />
                        </FBSProvider>
                    </ServerSideExperimentProvider>
                </ModalHandler>
            </ErrorBoundary>,
            heroConainerNew
        );
    }

    const budgetCalculatorContainer = document.getElementById('budget-calculator-section');

    if (budgetCalculatorContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <ServerSideExperimentProvider>
                    <FBSProvider>
                        <BudgetCalculatorContainer
                            fullStoryPageId={FULL_STORY_HOME_PAGE_ID}
                            financeCalculatorName="HP Calculator"
                        />
                    </FBSProvider>
                </ServerSideExperimentProvider>
            </ErrorBoundary>,
            budgetCalculatorContainer
        );
    }

    initDialogs();

    const localCars = document.getElementById('local-cars');

    if (localCars) {
        ReactDOM.render(
            <ErrorBoundary>
                <ModalHandler>
                    <LocalCarsLoader />
                </ModalHandler>
            </ErrorBoundary>,
            localCars
        );
    }

    const socialProofContainer = document.getElementById('social-proof');

    if (socialProofContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <SocialProofContainer
                    fullStoryPageId={FULL_STORY_HOME_PAGE_ID}
                    nextFocus="#research-articles-component"
                />
            </ErrorBoundary>,
            socialProofContainer
        );
    }

    const instantOffersContainer = document.getElementById(instantOffersSectionId);

    if (instantOffersContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <InstantOffersLoader />
            </ErrorBoundary>,
            instantOffersContainer
        );
    }

    const researchArticleContainer = document.getElementById('research-articles-carousel-container');

    if (researchArticleContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <ResearchArticlesContainer fullStoryPageId={FULL_STORY_HOME_PAGE_ID} nextFocus="#homepage-links" />
            </ErrorBoundary>,
            researchArticleContainer
        );
    }

    const statePricingDisclaimerContainer = document.getElementById('state-pricing-disclaimer');

    if (statePricingDisclaimerContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <StatePricingDisclaimerContainer fullStoryPageId={FULL_STORY_HOME_PAGE_ID} />
            </ErrorBoundary>,
            statePricingDisclaimerContainer
        );
    }
}

onDomContentLoaded(() => {
    startup();
});
