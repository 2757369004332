import { Store } from '../types/store';

export const COOKIES = {
    myKmx: 'KmxMyKmx_0',
    visitor: 'KmxVisitor_0',
    authSession: 'KmxAuthSession',
    mxMyKmxInfo: 'KmxMyKmxInfo',
};

export const isAuthenticated = (): boolean => {
    try {
        return !!document.cookie.match(COOKIES.myKmx);
    } catch (e) {
        return false;
    }
};
export const getMyKmxUserId = (): string => {
    try {
        if (isAuthenticated()) {
            return document.cookie.split(`${COOKIES.myKmx}=`)[1].split(';')[0].split('userid=')[1];
        }
        return '';
    } catch (e) {
        return '';
    }
};
export const hasSignedIn = (): boolean => {
    try {
        const myKmxUserId = document.cookie.split(`${COOKIES.visitor}=`)[1].split(';')[0].split('MyKmxUser=')[1];
        return myKmxUserId !== '' && myKmxUserId.length > 0;
    } catch (e) {
        return false;
    }
};
export const hasAuthSession = (): boolean => {
    try {
        const authSession = document.cookie.split(`${COOKIES.authSession}=`)[1].split(';')[0].split('tk=')[1];

        return authSession !== '' && authSession.length > 0;
    } catch (e) {
        return false;
    }
};
export const hasPreviouslySignedIn = (): boolean => {
    try {
        return hasSignedIn() && !hasAuthSession();
    } catch (e) {
        return false;
    }
};

export const mxMyKmxInfoObject = (): any => createObjectFromCookie(COOKIES.mxMyKmxInfo);

const createObjectFromCookie = (cookieName: string) => {
    try {
        const cookieInfo = document.cookie.split(`${cookieName}=`)[1].split(';')[0];
        return splitCookieData(cookieInfo.split('&'));
    } catch (e) {
        return null;
    }
};

export const KmxVisitorObject = (): any => createObjectFromCookie(COOKIES.visitor);

function splitCookieData(data: any): any {
    if (data !== null) {
        let dataArray = new Map();
        dataArray = data.map((item: any) => [
            item.substring(0, item.indexOf('=')),
            item.substring(item.indexOf('=') + 1),
        ]);
        const cookieData = Object.fromEntries(dataArray);
        return cookieData;
    } else {
        return null;
    }
}

export const getStoreId = (): string | undefined => {
    return getCookieValue(COOKIES.visitor, 'StoreId');
};

export const getStoreZipCode = (): string | undefined => {
    return getCookieValue(COOKIES.visitor, 'Zip');
};

const getCookieValue = (cookieName: string, key: string): string | undefined => {
    const cookie = createObjectFromCookie(cookieName);
    return cookie?.[key];
};
