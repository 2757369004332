import * as React from 'react';
import { IRecentSearchTile } from '../../../pages/home/utilities/types';
import Carousel from './Carousel';
import RecentSearchTile from '../../../pages/home/hero/recent-search-tile/RecentSearchTile';
import { KMXMenu } from '@kmx/legos-menu';

interface IReturnVisitorRecentSearchesCarouselProps {
    isVisible: boolean;
    recentSearchTileList: IRecentSearchTile[];
    analyticsName: string;
    carouselName: string;
    ariaLabel: string;
}

interface IReturnVisitorRecentSearchesCarouselState {
    recentSearchTileList: IRecentSearchTile[];
    carouselItems: React.ReactElement[];
    menus: any;
}

export default class ReturnVisitorRecentSearchesCarousel extends React.Component<
    IReturnVisitorRecentSearchesCarouselProps,
    IReturnVisitorRecentSearchesCarouselState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            recentSearchTileList: this.props.recentSearchTileList,
            carouselItems: this.buildCarouselItems(this.props.recentSearchTileList),
            menus: [],
        };

        this.buildCarouselItems = this.buildCarouselItems.bind(this);
        this.closeKebabMenus = this.closeKebabMenus.bind(this);
    }

    private buildCarouselItems(recentSearchTileList: IRecentSearchTile[]): React.ReactElement[] {
        const carouselItems: React.ReactElement[] = [];

        if (recentSearchTileList && recentSearchTileList.length > 0) {
            recentSearchTileList.forEach((recentSearchTile: IRecentSearchTile, index: number) => {
                const linkAttrs: { [key: string]: any } = {};
                linkAttrs.tabIndex = 0;

                carouselItems.push(
                    <div className="carousel__slide" key={`recent-search-slide-${index}`}>
                        <div className="carousel-slide-item">
                            <RecentSearchTile linkAttrs={linkAttrs} recentSearchTile={recentSearchTile} />
                        </div>
                    </div>
                );
            });
        }

        return carouselItems;
    }

    private closeKebabMenus() {
        this.state.menus.forEach((KMXMenu: any) => {
            KMXMenu.handleMenuTab();
        });
    }

    public componentDidMount(): void {
        const menuContainers = document.querySelectorAll('#kmx-recent-searches-carousel .kmx-menu-container');
        const menuInstances: any = [];
        menuContainers.forEach((container) => {
            menuInstances.push(new KMXMenu(container));
        });
        this.setState({ menus: menuInstances });
    }

    public componentDidUpdate(prevProps: IReturnVisitorRecentSearchesCarouselProps): void {
        const recentSearchTilesHaveUpdated =
            JSON.stringify(prevProps.recentSearchTileList) !== JSON.stringify(this.props.recentSearchTileList);

        if (recentSearchTilesHaveUpdated) {
            this.closeKebabMenus();
            this.setState({
                carouselItems: this.buildCarouselItems(this.props.recentSearchTileList),
            });
        }
    }

    public render(): React.ReactNode {
        return (
            <div id="kmx-recent-searches-carousel" className="carousel-container">
                <div className="home-base-hero--grid-container">
                    <Carousel
                        name={`${this.props.carouselName}-return-visitor`}
                        nextFocus="#budget-calculator-section"
                        ariaLabel={this.props.ariaLabel}
                    >
                        {this.state.carouselItems.map((item) => item)}
                    </Carousel>
                </div>
            </div>
        );
    }
}
