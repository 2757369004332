import CarTile from '@kmx/shared-components/car-tile/CarTile';
import { IVehicleTile } from '../../../../types/vehicleTile';
import { vehicleIsUnavailable, buildStatusText } from '../../../../utilities/vehicleTileBuilder';
import { mapVehicleTileToCar } from './carTileUtils';
import { useEffect, useState, useContext } from 'react';
import Icons from '../../../../utilities/icons';
import { getCarImages } from '../../../../api/getCarImages';
import { FBSContext } from './../../../../context/';
import { IFBSDecision } from '../../../../api/types/finance';
import * as analytics from '../../../../utilities/analytics';
import TileKebabMenuItemSnackbar from '../tile-kebab-menu/TileKebabMenuItemSnackbar';

interface ICarTileWrapperProps {
    vehicle: IVehicleTile;
    isFavorite: boolean;
    onAddFavorite: (vehicleTile: IVehicleTile) => any;
    onRemoveFavorite: (vehicleTile: IVehicleTile) => any;
    updatedViewedTiles?: (VehicleTile: IVehicleTile) => any;
    enableRemove: boolean;
    isWaitingForFavoritesResponse: boolean;
}

export const CarTileWrapper = ({
    vehicle,
    updatedViewedTiles,
    isFavorite,
    enableRemove,
    onAddFavorite,
    onRemoveFavorite,
}: ICarTileWrapperProps) => {
    const car = mapVehicleTileToCar(vehicle);
    const [isFavoriteInUI, setIsFavoriteInUI] = useState<boolean>(isFavorite);
    const [isShareSnackbarOpen, setIsShareSnackbarOpen] = useState<boolean>(false);
    const ribbonText = buildStatusText(vehicle);
    const fbsContext = useContext(FBSContext);

    const mapDecisionToFbsApplication = (decision: IFBSDecision) => {
        const {
            applicationUrl,
            fbsDecision: { downPayment, expirationDateFormatted, stateAbbreviation, isSoftPull, tradeInBreakdown },
        } = decision;
        return {
            downPayment: downPayment,
            stateAbbreviation: stateAbbreviation,
            stationUrl: applicationUrl,
            expirationDateFormatted: expirationDateFormatted,
            isSoftPull: isSoftPull,
            tradeInBreakdown,
        };
    };

    const decision = fbsContext.fbsData.find((d) => d.stockNumber === vehicle.stockNumber);
    let fbsApplication: object | undefined;
    if (decision) {
        fbsApplication = mapDecisionToFbsApplication(decision);
    }

    const KEBAB_MENU = [
        {
            text: `${isFavoriteInUI ? 'Remove from' : 'Add to'} favorites`,
            icon: Icons.favoriteHeart,
            onClick: () => {
                handleHeartClick();
                analytics.track('car-tile-favorite');
            },
            className: `tile-menu-item--favorite ${isFavoriteInUI ? 'saved' : ''}`,
        },
        {
            text: 'Share',
            icon: Icons.share,
            onClick: () => {
                handleShareClick();
                analytics.track('car-tile-share');
            },
            className: 'tile-menu-item--share',
        },
    ];

    const CRITICAL_MENU_ITEM = {
        text: 'Remove',
        icon: Icons.remove,
        onClick: () => {
            handleRemoveVehicleTile();
            analytics.track('car-tile-remove');
        },
        className: 'tile-menu-item--remove',
    };

    useEffect(() => {
        setIsFavoriteInUI(isFavorite);
    }, [isFavorite]);

    const handleHeartClick = () => {
        setIsFavoriteInUI(!isFavorite);
        if (isFavorite) {
            onRemoveFavorite(vehicle);
        } else {
            onAddFavorite(vehicle);
        }
    };

    // Wrap the get request so we can indirectly provide the built in image carousel with our context env.
    const getImagesWithContext = (
        stockNumber: any,
        successCallback: (response: any) => void,
        errorCallback: (error: any) => void
    ) => {
        return getCarImages(stockNumber, successCallback, errorCallback);
    };

    const handleRemoveVehicleTile = async () => {
        if (updatedViewedTiles) {
            updatedViewedTiles(vehicle);
        }
    };

    const handleShareClick = async () => {
        if (navigator.share) {
            navigator
                .share({
                    title: `${document.title}.`,
                    text: `Check out ${document.title}.`,
                    url: `${window.location.origin}/car/${vehicle.stockNumber}`,
                })
                .then(() => console.log('Successful Car Tile Share'))
                .catch((error) => console.log('Error Sharing Car Tile', error));
        } else {
            await navigator.clipboard.writeText(`${window.location.origin}/car/${vehicle.stockNumber}`);
            setIsShareSnackbarOpen(true);
        }
    };

    return (
        <>
            <CarTile
                car={car}
                disableScrollNav={false}
                imageUrl={vehicle.imageLink}
                showFavorites={true}
                isDisabled={vehicleIsUnavailable(vehicle.statusCode, vehicle.isSaleable)}
                ribbonStyle="standard"
                ribbonText={ribbonText}
                isFavorite={isFavoriteInUI}
                menuItems={KEBAB_MENU}
                criticalMenuItem={enableRemove ? CRITICAL_MENU_ITEM : undefined}
                onFavorite={handleHeartClick}
                onUnfavorite={handleHeartClick}
                getCarImages={getImagesWithContext}
                fbsApplication={fbsApplication}
                fbsDecision={decision?.fbsDecision}
                downPayment={decision?.fbsDecision.downPayment}
                recommendationType={vehicle.recommendationType}
            />

            <TileKebabMenuItemSnackbar
                icon={Icons.share}
                isOpen={isShareSnackbarOpen}
                textLabel="Link copied to your clipboard"
                cancelAction={() => setIsShareSnackbarOpen(false)}
            />
        </>
    );
};
