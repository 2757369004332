import * as React from 'react';
import { IVehicleTile } from '../../../../types/vehicleTile';
import { ENABLE_TRANSFER_TILE_EVENT } from '../../utilities/constants';
import { TransfersTile, hasInProgressTransfer } from '../../utilities/transfersTileBuilder';
import { buildSavedVehicleTiles } from '../../../../utilities/vehicleTileBuilder';
import * as savedVehicles from '../../utilities/savedVehicles';
import { getStoreId } from '../../../../utilities/visitor-cookie';
import TileKebabMenuItemSnackbar from '../tile-kebab-menu/TileKebabMenuItemSnackbar';
import HomeBaseHeroDialog from './HomeBaseHeroDialog';
import HomeBaseTabs from './HomeBaseTabs';
import HomeBaseTransferStatusModal from './HomeBaseTransferStatusModal';

interface IHomeBaseHeroProps {
    fullStoryPageId: string;
    viewedVehicleList: IVehicleTile[];
    transfersTile: TransfersTile;
}

interface IHomeBaseHeroState {
    dialogOpen: boolean;
    snackbarOpen: boolean;
    favoritedStockNumberList: number[];
    stockNumbersPendingFavoritesUpdate: number[];
    favoritedVehicleList: IVehicleTile[];
    snackBarText: string;
    initTransferStatusModal: boolean;
}

export default class HomeBaseHero extends React.Component<IHomeBaseHeroProps, IHomeBaseHeroState> {
    constructor(props: IHomeBaseHeroProps) {
        super(props);

        this.state = {
            dialogOpen: false,
            snackbarOpen: false,
            favoritedStockNumberList: [],
            stockNumbersPendingFavoritesUpdate: [],
            favoritedVehicleList: [],
            snackBarText: '',
            initTransferStatusModal: false,
        };

        this.handleDialogOpen = this.handleDialogOpen.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleAddFavorite = this.handleAddFavorite.bind(this);
        this.handleRemoveFavorite = this.handleRemoveFavorite.bind(this);
        this.handleOpenSnackbar = this.handleOpenSnackbar.bind(this);
        this.handleEnablingTransferTile = this.handleEnablingTransferTile.bind(this);
        this.loadAndBuildSavedVehicleTiles = this.loadAndBuildSavedVehicleTiles.bind(this);
    }

    private enableHomebasePageStyles() {
        document.body.classList.add('is-return-visit');

        const headerEl = document.getElementById('header');
        if (headerEl) {
            headerEl.classList.remove('transparent-dark');
            headerEl.classList.add('transparent-light');
        }
    }

    private handleDialogOpen() {
        this.setState({
            dialogOpen: true,
        });
    }

    private handleDialogClose() {
        this.setState({
            dialogOpen: false,
        });
    }

    private handleAddFavorite(vehicleTile: IVehicleTile) {
        const stockNumbersPendingFavoritesUpdate = this.state.stockNumbersPendingFavoritesUpdate.concat(
            vehicleTile.stockNumber
        );
        this.setState(
            {
                stockNumbersPendingFavoritesUpdate: stockNumbersPendingFavoritesUpdate,
            },
            () => {
                savedVehicles.setSavedVehicle(vehicleTile.stockNumber, (success: boolean) => {
                    if (success && this.state.favoritedStockNumberList.indexOf(vehicleTile.stockNumber) === -1) {
                        const updatedFavoritedStockNumbers = Object.assign([], this.state.favoritedStockNumberList);
                        const updatedFavoritedVehicles = Object.assign([], this.state.favoritedVehicleList);
                        const updatedActivelyToggledFavorite = Array.from(
                            this.state.stockNumbersPendingFavoritesUpdate
                        );
                        const indexOfFavoriteToRemove = this.state.stockNumbersPendingFavoritesUpdate.indexOf(
                            vehicleTile.stockNumber
                        );

                        updatedActivelyToggledFavorite.splice(indexOfFavoriteToRemove, 1);
                        updatedFavoritedStockNumbers.unshift(vehicleTile.stockNumber);
                        updatedFavoritedVehicles.unshift(vehicleTile);

                        this.setState({
                            stockNumbersPendingFavoritesUpdate: updatedActivelyToggledFavorite,
                            favoritedStockNumberList: updatedFavoritedStockNumbers,
                            favoritedVehicleList: updatedFavoritedVehicles,
                        });
                    }
                });
            }
        );
    }

    private handleRemoveFavorite(vehicleTile: IVehicleTile) {
        const stockNumbersPendingFavoritesUpdate = this.state.stockNumbersPendingFavoritesUpdate.concat(
            vehicleTile.stockNumber
        );
        this.setState(
            {
                stockNumbersPendingFavoritesUpdate: stockNumbersPendingFavoritesUpdate,
            },
            () => {
                savedVehicles.removeSavedVehicle(vehicleTile.stockNumber, (success: boolean) => {
                    const indexOfStockNumber = this.state.favoritedStockNumberList.indexOf(vehicleTile.stockNumber);
                    const indexOfvehicleTile = this.state.favoritedVehicleList.indexOf(vehicleTile);

                    if (success && indexOfStockNumber > -1) {
                        const updatedFavoritedStockNumbers = Object.assign([], this.state.favoritedStockNumberList);
                        const updatedFavoritedVehicles = Object.assign([], this.state.favoritedVehicleList);
                        const updatedActivelyToggledFavorite = Array.from(
                            this.state.stockNumbersPendingFavoritesUpdate
                        );
                        const indexOfFavoriteToRemove = this.state.stockNumbersPendingFavoritesUpdate.indexOf(
                            vehicleTile.stockNumber
                        );

                        updatedActivelyToggledFavorite.splice(indexOfFavoriteToRemove, 1);
                        updatedFavoritedStockNumbers.splice(indexOfStockNumber, 1);
                        updatedFavoritedVehicles.splice(indexOfvehicleTile, 1);

                        this.setState({
                            stockNumbersPendingFavoritesUpdate: updatedActivelyToggledFavorite,
                            favoritedStockNumberList: updatedFavoritedStockNumbers,
                            favoritedVehicleList: updatedFavoritedVehicles,
                        });
                    }
                });
            }
        );
    }

    private loadAndBuildSavedVehicleTiles = (): void => {
        this.setState(() => {
            savedVehicles.getSavedVehicles((stockNumberList) => {
                buildSavedVehicleTiles(
                    stockNumberList,
                    (vehicleTileList: IVehicleTile[]) => {
                        this.setState({
                            favoritedStockNumberList: stockNumberList,
                            favoritedVehicleList: vehicleTileList,
                        });
                    },
                    getStoreId()
                );
            });
        });
    };

    private handleOpenSnackbar = (text: string): void => {
        this.setState({ snackBarText: text });
        this.setState({ snackbarOpen: true });
    };

    private handleEnablingTransferTile = (event: CustomEvent<{ enabled: boolean }>) => {
        if (event?.detail && this.hasTransferTile()) {
            this.setState({ initTransferStatusModal: event.detail.enabled });
        }
    };

    private hasTransferTile = (): boolean => {
        const { transfersTile } = this.props;
        return (
            transfersTile &&
            transfersTile.status !== '' &&
            transfersTile.vehicle &&
            Object.values(transfersTile.vehicle).length > 0
        );
    };

    public componentDidMount(): void {
        this.loadAndBuildSavedVehicleTiles();

        document.documentElement.addEventListener(
            ENABLE_TRANSFER_TILE_EVENT,
            this.handleEnablingTransferTile as EventListener
        );
    }

    public componentWillUnmount(): void {
        document.documentElement.removeEventListener(
            ENABLE_TRANSFER_TILE_EVENT,
            this.handleEnablingTransferTile as EventListener
        );
    }

    public render(): React.ReactNode {
        this.enableHomebasePageStyles();
        return (
            <div
                id="home-base-hero"
                data-fs-section={`${this.props.fullStoryPageId}: HomeBaseHero`}
                className="home-base-hero"
            >
                <picture className="home-base-hero__background-image">
                    <source media="(max-width: 474px)" srcSet="/images/home/hero/homebase-mobile.webp" />
                    <source
                        media="(min-width: 474.1px) and (max-width: 1104px)"
                        srcSet="/images/home/hero/homebase-tablet.webp"
                    />
                    <img className="home-base-hero__background-image" src="/images/home/hero/homebase-xl.webp" alt="" />
                </picture>

                {this.state.initTransferStatusModal && hasInProgressTransfer(this.props.transfersTile) ? (
                    <HomeBaseTransferStatusModal transfersTile={this.props.transfersTile} />
                ) : (
                    <HomeBaseTabs
                        viewedVehicleList={this.props.viewedVehicleList}
                        favoritedVehicleList={this.state.favoritedVehicleList}
                        favoritedStockNumberList={this.state.favoritedStockNumberList}
                        onAddFavorite={this.handleAddFavorite}
                        onRemoveFavorite={this.handleRemoveFavorite}
                        onOpenSnackbar={this.handleOpenSnackbar}
                        stockNumbersPendingFavoritesUpdate={this.state.stockNumbersPendingFavoritesUpdate}
                    />
                )}

                <TileKebabMenuItemSnackbar
                    icon={undefined}
                    isOpen={this.state.snackbarOpen}
                    textLabel={this.state.snackBarText}
                    cancelAction={() => {
                        this.setState({ snackbarOpen: false });
                    }}
                />
                <HomeBaseHeroDialog open={this.state.dialogOpen} onClose={this.handleDialogClose} />
            </div>
        );
    }
}
