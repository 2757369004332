import * as React from 'react';
import LoadingSpinner from '../../../components/loading-spinner';
import FirstTimeHero from './first-time/FirstTimeHero';
import HomeBaseHero from './home-base/HomeBaseHero';
import { getRecentlyViewedVehicleTiles } from '../../../utilities/vehicleTileBuilder';
import { IVehicleTile } from '../../../types/vehicleTile';
import { MAX_RECENTLY_VIEWED_VEHICLE_TILES } from '../utilities/constants';
import * as savedVehicles from '../utilities/savedVehicles';
import { getStoreId } from '../../../utilities/visitor-cookie';
import { FBSContext } from '../../../context';
import {
    TransfersTile,
    fetchAndBuildTransfersTile,
    hasCheckoutCreatedTransfer,
    hasInProgressTransfer,
} from '../utilities/transfersTileBuilder';

interface IHeroContainerProps {
    fullStoryPageId: string;
}

interface IHeroContainerState {
    hasLoadedViewedVehicles: boolean;
    isLoadingViewedVehicles: boolean;
    hasLoadedSavedVehiclesAssets: boolean;
    isLoadingSavedVehiclesAssets: boolean;
    hasLoadedTransferredVehicle: boolean;
    isLoadingTransferredVehicle: boolean;
    viewedVehicleTileList: IVehicleTile[];
    transfersTile: TransfersTile;
}

export default class HeroContainer extends React.Component<IHeroContainerProps, IHeroContainerState> {
    static contextType?: React.Context<any> | undefined = FBSContext;
    context!: React.ContextType<typeof FBSContext>;

    constructor(props: IHeroContainerProps) {
        super(props);

        this.state = {
            hasLoadedViewedVehicles: false,
            isLoadingViewedVehicles: true,
            hasLoadedSavedVehiclesAssets: false,
            isLoadingSavedVehiclesAssets: true,
            hasLoadedTransferredVehicle: false,
            isLoadingTransferredVehicle: true,
            viewedVehicleTileList: [],
            transfersTile: {} as TransfersTile,
        };

        this.loadSavedVehiclesAssets = this.loadSavedVehiclesAssets.bind(this);
        this.loadRecentlyViewedVehicleTiles = this.loadRecentlyViewedVehicleTiles.bind(this);
        this.loadTransfersTile = this.loadTransfersTile.bind(this);
    }

    public componentDidMount() {
        this.loadSavedVehiclesAssets();
        this.loadRecentlyViewedVehicleTiles();
        this.loadTransfersTile();
    }

    private loadSavedVehiclesAssets() {
        savedVehicles.loadSavedVehiclesAssets(() => {
            this.setState({
                hasLoadedSavedVehiclesAssets: true,
                isLoadingSavedVehiclesAssets: false,
            });
        });
    }

    private loadRecentlyViewedVehicleTiles = (): void => {
        this.setState({ isLoadingViewedVehicles: true }, () => {
            getRecentlyViewedVehicleTiles(MAX_RECENTLY_VIEWED_VEHICLE_TILES, getStoreId()).then(
                (vehicleTileList: IVehicleTile[]) =>
                    this.setState(
                        {
                            hasLoadedViewedVehicles: true,
                            isLoadingViewedVehicles: false,
                            viewedVehicleTileList: vehicleTileList,
                        },
                        () => {
                            this.context.setVehicleList(vehicleTileList);
                        }
                    )
            );
        });
    };

    private loadTransfersTile = (): void => {
        this.setState({ isLoadingTransferredVehicle: true }, () => {
            fetchAndBuildTransfersTile().then((transfersTile) =>
                this.setState({
                    hasLoadedTransferredVehicle: true,
                    isLoadingTransferredVehicle: false,
                    transfersTile: transfersTile,
                })
            );
        });
    };

    public render(): React.ReactNode {
        const {
            hasLoadedViewedVehicles,
            isLoadingViewedVehicles,
            hasLoadedSavedVehiclesAssets,
            isLoadingSavedVehiclesAssets,
            hasLoadedTransferredVehicle,
            isLoadingTransferredVehicle,
            viewedVehicleTileList,
            transfersTile,
        } = this.state;

        if (isLoadingViewedVehicles || isLoadingSavedVehiclesAssets || isLoadingTransferredVehicle) {
            return (
                <div className="hero-loading-container">
                    <LoadingSpinner />
                </div>
            );
        } else {
            if (hasLoadedViewedVehicles && hasLoadedSavedVehiclesAssets && hasLoadedTransferredVehicle) {
                if (hasCheckoutCreatedTransfer(transfersTile)) {
                    return <FirstTimeHero fullStoryPageId={this.props.fullStoryPageId} />;
                }

                if (viewedVehicleTileList.length > 0 || hasInProgressTransfer(transfersTile)) {
                    return (
                        <HomeBaseHero
                            fullStoryPageId={this.props.fullStoryPageId}
                            viewedVehicleList={viewedVehicleTileList}
                            transfersTile={transfersTile}
                        />
                    );
                }
            }
            return <FirstTimeHero fullStoryPageId={this.props.fullStoryPageId} />;
        }
    }
}
