import * as React from 'react';
import {
    HomebaseTab,
    HOMEBASE_CAROUSELS,
    LOADING_SPINNER_CLASS,
    HOME_BASE_RECOMMENDATION_ANALYTICS
} from '../../../utilities/constants';
import { IVehicleTile } from '../../../../../types/vehicleTile';
import LoadingSpinner from '../../../../../components/loading-spinner';
import ReturnVisitorCarousel from '../../../../../components/carousels/home-base-hero-carousel/ReturnVisitorCarousel';
import { RecommendationsPlaceholderCarousel } from '../../../../../components/carousels/home-base-hero-carousel/RecommendationsPlaceholderCarousel';
import { RECOMMENDATION_ALGORITHMS } from '../../../../../api/vehicle-recommendations-api';
import { FBSContext } from '../../../../../context';

interface IRecommendedCarouselProps {
    hasLoadedRecommendedTab: boolean;
    recommendedVehicleList: IVehicleTile[];
    recommendationAlgorithm: RECOMMENDATION_ALGORITHMS;
    isVisible: boolean;
    favoritedStockNumberList: number[];
    onAddFavorite: (vehicleTile: IVehicleTile) => any;
    onRemoveFavorite: (vehicleTile: IVehicleTile) => any;
    isLoadingRecommendedTab: boolean;
    stockNumbersPendingFavoritesUpdate: number[];
}

const RecommendedCarousel = (props: IRecommendedCarouselProps) => {
    const {
        hasLoadedRecommendedTab,
        recommendedVehicleList,
        recommendationAlgorithm,
        isVisible,
        favoritedStockNumberList,
        onAddFavorite,
        onRemoveFavorite,
        isLoadingRecommendedTab,
        stockNumbersPendingFavoritesUpdate
    } = props;

    const fbsContext = React.useContext(FBSContext);

    React.useEffect(() => {
        if (recommendedVehicleList.length !== 0 && recommendedVehicleList) {
            fbsContext.setVehicleList(recommendedVehicleList);
        }
    }, [recommendedVehicleList]);

    return hasLoadedRecommendedTab && recommendedVehicleList.length > 0 ? (
        <ReturnVisitorCarousel
            id="kmx-recommended-carousel"
            carouselName={HOMEBASE_CAROUSELS.byId[HomebaseTab.Recommended].carouselName}
            analyticsName={HOMEBASE_CAROUSELS.byId[HomebaseTab.Recommended].carouselAnalyticsName}
            vehicleTileLinkAttrs={{
                'data-vehicle-rec': HOME_BASE_RECOMMENDATION_ANALYTICS[recommendationAlgorithm].MODULE_NAME
            }}
            isVisible={isVisible}
            vehicleTileList={recommendedVehicleList}
            favoritedStockNumberList={favoritedStockNumberList}
            stockNumbersPendingFavoritesUpdate={stockNumbersPendingFavoritesUpdate}
            onAddFavorite={onAddFavorite}
            onRemoveFavorite={onRemoveFavorite}
            ariaLabel={HOMEBASE_CAROUSELS.byId[HomebaseTab.Recommended].ariaLabel}
            enableRemove={false}
            fbsData={fbsContext.fbsData}
        />
    ) : isLoadingRecommendedTab ? (
        <LoadingSpinner className={LOADING_SPINNER_CLASS} />
    ) : hasLoadedRecommendedTab && recommendedVehicleList.length === 0 ? (
        <RecommendationsPlaceholderCarousel />
    ) : null;
};

export default RecommendedCarousel;
