import { IVehicleTile } from "../../../../types/vehicleTile";

export const mapVehicleTileToCar = (vehicleTile: IVehicleTile) => {
    return {
        ...vehicleTile,
        imageCount: 1, // TODO
        highlights: [], // TODO
        transferText: vehicleTile.shippingText
    };
};
