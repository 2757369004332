import { FBSContext } from '../../../../context';
import { IVehicleTile } from '../../../../types/vehicleTile';
import HomeBaseHero from '../../hero/home-base/HomeBaseHero';
import { TransfersTile } from '../../utilities/transfersTileBuilder';
import { useHomeStore } from '../Store';
import { IHomeBase } from '../Store/models';
import { useContext, useEffect } from 'react';

function HomeBase() {
    const homeStore = useHomeStore().experienceData;
    const fbsContext = useContext(FBSContext);

    const { viewedVehicles } = (homeStore as unknown) as IHomeBase;

    useEffect(() => {
        if (viewedVehicles.length > 0 && viewedVehicles) {
            fbsContext.setVehicleList(viewedVehicles);
        }
    }, [viewedVehicles, fbsContext]);

    return (
        <HomeBaseHero
            transfersTile={{} as TransfersTile}
            fullStoryPageId="HP"
            viewedVehicleList={viewedVehicles as IVehicleTile[]}
        />
    );
}

export default HomeBase;
