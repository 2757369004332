import { MAX_BEST_MATCH_VEHICLE_TILES } from './constants';
import { RECOMMENDATION_ALGORITHMS } from '../../../api/vehicle-recommendations-api';
import { getRecommendedVehicleTiles, getBestMatchVehicleTiles } from '../../../utilities/vehicleTileBuilder';
import { getStoreId, getVisitorId } from '../../../utilities/visitor-cookie';
import { IVehicleTile } from '../../../types/vehicleTile';

export const selectSourceStockNumbers = (favoritedStockNumberList?: number[], viewedVehicleList?: IVehicleTile[]) => {
    if (favoritedStockNumberList && favoritedStockNumberList.length > 0) {
        return favoritedStockNumberList.map(stockNumber => String(stockNumber));
    } else if (viewedVehicleList && viewedVehicleList.length > 0) {
        return viewedVehicleList.map(vehicleTile => String(vehicleTile.stockNumber));
    } else {
        return [];
    }
};

export const fetchAndBuildRecommendedVehicleTiles = (
    recommendationAlgorithm: RECOMMENDATION_ALGORITHMS,
    cb: (recommendedVehicleTileList: IVehicleTile[]) => any,
    favoritedStockNumberList?: number[],
    viewedVehicleList?: IVehicleTile[]
) => {
    const sourceStockNumbers = selectSourceStockNumbers(favoritedStockNumberList, viewedVehicleList);

    if (sourceStockNumbers.length > 0) {
        getRecommendedVehicleTiles(sourceStockNumbers, getStoreId(), getVisitorId(), recommendationAlgorithm).then(
            (recommendedVehicleList: IVehicleTile[]) => {
                if (recommendedVehicleList.length > 0) {
                    cb(recommendedVehicleList);
                } else {
                    getBestMatchVehicleTiles(MAX_BEST_MATCH_VEHICLE_TILES, getStoreId()).then(
                        (bestMatchVehicleList: IVehicleTile[]) => cb(bestMatchVehicleList)
                    );
                }
            }
        );
    } else {
        getBestMatchVehicleTiles(MAX_BEST_MATCH_VEHICLE_TILES, getStoreId()).then(
            (bestMatchVehicleList: IVehicleTile[]) => cb(bestMatchVehicleList)
        );
    }
};
