import { Names, IHomeBase } from '../Store/models';
import { getRecentlyViewedVehicleTiles } from '../../../../utilities/vehicleTileBuilder';
import { getStoreId } from '../../../../utilities/visitor-cookie';
import { IVehicleTile } from '../../../../types/vehicleTile';

export const getHomeBaseData = new Promise((resolve) => {
    getRecentlyViewedVehicleTiles(10, getStoreId()).then((vehicleTileList: IVehicleTile[]) => {
        const newValue = {
            name: Names.HomeBase,
            viewedVehicles: vehicleTileList,
        };
        resolve(newValue);
    });
});

export const fetchAsyncData = async () => {
    const res = await Promise.all([getHomeBaseData]);
    return res;
};

function returnExperienceByName({ experiences, name }: { experiences: any[]; name: Names }) {
    const experience = experiences.find((experiences: IHomeBase | unknown) => {
        return (experiences as IHomeBase).name === name;
    });
    return experience;
}

export const getComponentAndData = (experiences: (IHomeBase | unknown)[]) => {
    const HomeBaseExperience = returnExperienceByName({ experiences, name: Names.HomeBase });
    if (HomeBaseExperience.viewedVehicles.length > 0) {
        return HomeBaseExperience;
    } else {
        return { name: Names.FirstTime, experienceData: undefined };
    }
};
