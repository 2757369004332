import * as React from 'react';
import { HeroTheme } from '../utilities/constants';

interface IHeroFeaturedContentProps {
    heroTheme: HeroTheme.Light | HeroTheme.Dark;
    ctaLabel: string;
}

interface IHeroFeaturedContentState {
    dialogOpen: boolean;
}

export default class HeroFeaturedContent extends React.Component<IHeroFeaturedContentProps, IHeroFeaturedContentState> {
    constructor(props: IHeroFeaturedContentProps) {
        super(props);

        this.state = {
            dialogOpen: false,
        };

        this.handleDialogClose = this.handleDialogClose.bind(this);
    }

    private handleDialogClose() {
        this.setState({
            dialogOpen: false,
        });
    }

    public render(): React.ReactNode {
        return (
            <div
                className={`hero-featured-content hero-featured-content__${this.props.heroTheme} mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop`}
            >
                <div className="hero-featured-content--content-container">
                    <h1 className="hero-featured-content--heading">
                        <span className="hero-featured-content--heading-top">
                            <span>The way car</span>
                        </span>
                        <span className="hero-featured-content--heading-bottom">
                            <span>buying should be</span>
                        </span>
                    </h1>
                    <div className="hero-featured-content--subheading">
                        <span className="hero-featured-content--subheading-copy kmx-typography--fine-print">
                            Upfront prices. CarMax Certified quality.
                        </span>
                    </div>
                    {featureFlags.enableHomepageBrandRefresh ? (
                        <>
                            <a className="test--brand-refresh-cta" data-kmx-analytics="searchInit" href="/">
                                <div className="test-cta__hover-state-layer"></div>
                                <div className="test-cta-inner-wrapper">
                                    <span>Search cars</span>
                                </div>
                            </a>
                        </>
                    ) : (
                        <hzn-button href="/cars/all" data-kmx-analytics="searchInit" class="hero-featured-content--cta">
                            {this.props.ctaLabel}
                        </hzn-button>
                    )}
                </div>
            </div>
        );
    }
}
