import * as React from 'react';
import classNames from 'classnames';
import { ISocialProofApiPost } from '../../../api/social-proof-api';
import { ISocialProofPost } from './SocialProofPostBuilder';
import Icons from '../../../utilities/icons';

export interface ISocialProofCardProps extends ISocialProofPost, ISocialProofApiPost {
    itemIndex: number;
    onLoad: (id: string) => void;
    onError: (id: string) => void;
}

interface ISocialProofCardState {
    hasLoaded: boolean;
}

export class SocialProofCard extends React.Component<ISocialProofCardProps, ISocialProofCardState> {
    constructor(props: ISocialProofCardProps) {
        super(props);

        this.state = {
            hasLoaded: false,
        };
    }

    public render(): React.ReactNode {
        const { thumbnailUrl, authorName, imgAlt } = this.props;

        return (
            <>
                <article
                    className={classNames(this.props.horizDirection, 'social-proof-card cta')}
                    data-index={this.props.itemIndex}
                    // TODO figure out why we have this set with a tab index on an article
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex={0}
                >
                    <img
                        loading="lazy"
                        onError={(e) => {
                            this.props.onError(this.props.postId);
                        }}
                        className={classNames('social-proof-card--img')}
                        src={thumbnailUrl}
                        alt={imgAlt}
                    />
                    {!featureFlags.enableHomepageBrandRefresh ? (
                        <div className="social-proof-card--label">
                            {Icons.instagram}
                            <div className="social-proof-card--label-author kmx-typography--headline-2">
                                @{authorName}
                            </div>
                        </div>
                    ) : (
                        false
                    )}
                </article>
                {featureFlags.enableHomepageBrandRefresh ? (
                    <div className="social-proof-card--label test--rebrand">
                        {Icons.instagram}
                        <div className="social-proof-card--label-author kmx-typography--headline-2 test--rebrand">
                            @{authorName}
                        </div>
                    </div>
                ) : (
                    false
                )}
            </>
        );
    }
}
